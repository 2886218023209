<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b"><el-button type="primary" size="small" @click="addCustomerShopAddress"
						v-right-code="'Customershopaddress:Add'">新增</el-button>
				</div>
				<div class="h-b"><el-button type="text" size="small" @click="saveCustomerShopAddress"
						v-right-code="'Customershopaddress:Add'">保存</el-button>
				</div>
			</div>
		</div>

		<div class="form-list">
			<el-form ref="_customerShopAddressForm" :model="dataSource">
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="CustomerName">
							<!-- 	<el-input v-model="dataSource.EnterpriseName" :maxlength="30" :minlength="4"
								placeholder="客户名称"></el-input> -->
							<el-select v-model="dataSource.CustomerCode" collapse-tags placeholder="请选择"
								:maxlength="200" :disabled="isDisabled">
								<el-option v-for="item in dataSource.CustomerList" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>

						</el-form-item>
					</el-col>
					<div :hidden="!isDisabled">
						<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编码：</el-col>
						<el-col :span="7">
							<el-form-item prop="CustomerCode">
								<el-input v-model="dataSource.CustomerCode" :maxlength="30" :minlength="4"
									:disabled="true" placeholder="客户编码"></el-input>
							</el-form-item>
						</el-col>
					</div>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>店铺：</el-col>
					<el-col :span="7">
						<el-form-item>
							<el-input v-model.trim="dataSource.ShopName" readonly suffix-icon="el-icon-search"
								:maxlength="100" :minlength="2" placeholder="请选择店铺"
								@click.native="btnShopClick"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">仓库：</el-col>
					<el-col :span="7">
						<el-form-item prop="ShopContactName">
							<el-input v-model.trim="dataSource.WarehouseName" readonly suffix-icon="el-icon-search"
								:maxlength="100" :minlength="2" placeholder="请选择仓库"
								@click.native="btnWarehouseClick"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">来源电商平台：</el-col>
					<el-col :span="7">
						<el-form-item prop="SourceTradeNo">
							<el-select v-model.trim="dataSource.SourceTradeNo" filterable clearable>
								<el-option v-for="item in dataSource.SourceSystemNames" :key="item.StringValue"
									:label="item.label" :value="item.StringValue">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人姓名：</el-col>
					<el-col :span="7">
						<el-form-item prop="ShopContactName">
							<el-input v-model.trim="dataSource.ShopContactName" :maxlength="64"
								placeholder="联系人姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人电话：</el-col>
					<el-col :span="7">
						<el-form-item prop="ShopContactPhone">
							<el-input v-model.trim="dataSource.ShopContactPhone" :maxlength="128"
								placeholder="联系人电话"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>地区：
					</el-col>
					<el-col :span="3">
						<el-input v-model.trim="dataSource.ProvinceName" :maxlength="64" placeholder="省"></el-input>
					</el-col>
					<el-col :span="4">
						<el-input v-model.trim="dataSource.CityName" :maxlength="64" placeholder="市"></el-input>
					</el-col>
					<el-col :span="4">
						<el-input v-model.trim="dataSource.CountyName" :maxlength="64" placeholder="区"></el-input>
					</el-col>
					<el-col :span="6">
						<el-input v-model.trim="dataSource.Town" :maxlength="64" placeholder="乡镇"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>详细地址：
					</el-col>
					<el-col :span="17">
						<el-form-item prop="ShopAddress">
							<el-input v-model.trim="dataSource.ShopAddress" :maxlength="200"
								placeholder="详细地址"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
			</el-form>
		</div>
		<DialogEx :options="shopOptions" title="选择店铺" @onOk="onShopOk">
			<Shop ref="shop" :config="shopConfig" @onSelectedRow="onShopSelectedRow"></Shop>
		</DialogEx>
		<DialogEx :options="warehouseOptions" title="选择仓库" @onOk="onWarehouseOk">
			<Warehouse ref="warehouse" :config="warehouseConfig" @onSelectedRow="onWarehouseSelectedRow"></Warehouse>
		</DialogEx>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				shopOptions: {
					visible: false,
					size: 'small'
				},
				shopConfig: {
					isDetailDisplay: true,
					CustomerCode: ""
				},
				warehouseOptions: {
					visible: false,
					size: 'small'
				},
				warehouseConfig: {
					isDetailDisplay: true,
					CustomerCode: ""
				},
				multipleSelection: [],
				isDisabled: false,
				customerAddressCheckRule: {},
				currentSelectedRow: {}
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			dataSource: {}
		},
		mounted() {
			this.Event.$on("clearEditCustomerShopAddressForm", () => this.resetForm);
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = null != this.dataSource.CustomerAddressID;
				},
				deep: true
			}
		},
		created: function() {
			this.Event.$on("clearForm", this.resetForm);
		},
		methods: {
			addCustomerShopAddress() {
				this.Event.$emit("onAddCustomerShopAddress");
			},
			resetForm() {
				!this.isPassValidate && this.$refs['_customerShopAddressForm'].resetFields(); //清空表单
			},
			saveCustomerShopAddress() {
				var _this = this;
				_this.$refs["_customerShopAddressForm"].validate((valid) => {
					_this.isPassValidate = valid;
					if (valid) {
						var routeName = _this.dataSource.CustomerShopAddressId === null ? "add" :
							"edit";
						_this.$ajax.send("omsapi/customershopaddress/" + routeName, "post", _this.dataSource, (
							data) => {
							_this.dataSource.CustomerShopAddressId === null && (_this
								.dataSource.CustomerShopAddressId = data.Result);
							_this.$parent.syncDataSource();
							_this.Event.$emit("reloadCustomerShopAddressPageList", data.Result);
							_this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						return false;
					}
				});
			},
			onShopSelectedRow: function(row) {
				this.currentShopSelectedRow = row;
			},
			onShopOk: function() {
				var _this = this;
				var row = this.currentShopSelectedRow;
				if (row instanceof Object && row.ShopID != this.Utils.emptyGuid) {
					this.dataSource.ShopNo = row.ShopNo;
					this.dataSource.ShopName = row.ShopName;
					this.dataSource.ShopContactName = row.ShopContactName;
					this.dataSource.ShopContactPhone = row.ShopContactPhone;
				}
			},
			onCustomerAddressSelectedRow: function(row) {
				this.currentSelectedRow = row;
			},
			onWarehouseSelectedRow: function(row) {
				this.currentWarehouseSelectedRow = row;
			},
			onWarehouseOk: function() {
				var row = this.currentWarehouseSelectedRow;
				if (row instanceof Object && row.WarehouseNo != '' && row.WarehouseNo != undefined) {
					this.dataSource.WarehouseNo = row.WarehouseNo;
					this.dataSource.WarehouseName = row.WarehouseName;
				}
			},
			btnShopClick: function() {
				if (this.dataSource.CustomerCode == null || this.dataSource.CustomerCode == "" || this.dataSource
					.CustomerCode == undefined) {
					this.Utils.messageBox("请选择货主.", "error");
					return;
				}
				this.shopConfig.CustomerCode = this.dataSource.CustomerCode;
				this.shopOptions.visible = true;
			},
			btnWarehouseClick:function(){
				if (this.dataSource.CustomerCode == null || this.dataSource.CustomerCode == "" || this.dataSource
					.CustomerCode == undefined) {
					this.Utils.messageBox("请选择货主.", "error");
					return;
				}
				this.warehouseConfig.CustomerCode = this.dataSource.CustomerCode;
				this.warehouseOptions.visible=true;
			}

		},
		components: {
			"Shop": resolve => {
				require(['../../../../enterprise/selector/shopselector.vue'], resolve)
			},
			"Warehouse": resolve => {
				require(['../../../../enterprise/selector/warehouseselector'], resolve)
			},
		}
	}
</script>

<style>
</style>